.search-view {
    position: relative;
    width: 100%;
  }
  
  .suggestions-list {
    position: absolute;
    z-index: 1000;
    width: 100%;
    background: white;
    border: 1px solid #ddd;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
    margin-top: 40px;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  