/* Main Container */
.payment-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background: white;
  border-radius: 20px;
}

/* Title */
.payment-title {
  font-size: 28px;
  font-weight: 700;
  color: #1a1f36;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 15px;
}

.payment-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--primary-color);
  border-radius: 2px;
}

/* Form Groups */
.form-group {
  margin-bottom: 25px;
}

.payment-input-label {
  display: block;
  font-size: 0.95rem;
  font-weight: 600;
  color: #1a1f36;
  margin-bottom: 10px;
}

/* Select Container */
.select-container {
  position: relative;
}

.select-container select {
  appearance: none;
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  border: 2px solid #e5e9f2;
  border-radius: 12px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-container::after {
  content: '▼';
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #64748b;
  pointer-events: none;
  transition: all 0.3s ease;
}

.select-container select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
}

/* Input Fields */
.form-control1 {
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  border: 2px solid #e5e9f2;
  border-radius: 12px;
  transition: all 0.3s ease;
  margin-bottom: 15px;
}

.form-control1:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
  outline: none;
}

/* MPesa Logo */
.mpesa-logo {
  display: block;
  max-width: 200px;
  margin: 0 auto 25px;
  border-radius: 12px;
  padding: 15px;
  background: #f8f9fa;
}

/* Amount Displays */
.total-amount {
  font-size: 1.4rem;
  color: #1a1f36;
  margin: 25px 0 15px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid var(--primary-color);
}

.total-amount-sub {
  font-size: 1rem;
  color: #64748b;
  margin-bottom: 8px;
  padding: 8px 0;
}


.payment-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Payment Links */
.payment-link {
  color: var(--primary-color) !important;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.payment-link:hover {
  text-decoration: underline;
}

/* Loading Screen */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.loader-content {
  background: white;
  padding: 30px 40px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.loader-content p {
  font-size: 1.2rem;
  color: #1a1f36;
  margin: 0;
}

/* Product List Section */
.product-list1 {
  margin-top: 30px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 16px;
}

.product-list1 h5 {
  color: #1a1f36;
  margin-bottom: 20px;
}

/* Cart Items */
.cart-list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  background: white;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.cart-item-image img {
  border-radius: 8px;
  object-fit: cover;
}

.cart-item-name {
  font-size: 1.1rem;
  color: #1a1f36;
  margin-bottom: 8px;
}

/* Alert Messages */
.alert {
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: none;
}

.alert-success {
  background: #dcfce7;
  color: #166534;
}

.alert-warning {
  background: #fef3c7;
  color: #92400e;
}

.alert-danger {
  background: #fee2e2;
  color: #991b1b;
}

/* Responsive Design */
@media (max-width: 768px) {
  .payment-container {
    padding: 20px;
  }

  .payment-title {
    font-size: 24px;
  }

  .cart-list-item {
    flex-direction: column;
    text-align: center;
  }

  .cart-item-details {
    margin: 15px 0;
  }
}