.payment-success-container {
    padding: 50px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
  }
  
  .success-icon-container {
    margin-bottom: 20px;
  }
  
  .success-icon {
    width: 100px;
    height: 100px;
  }
  
  .success-title {
    font-size: 2.5rem;
    color: #28a745;
    margin-bottom: 20px;
  }
  
  .success-message {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .order-number {
    font-size: 1.5rem;
    color:var(--primary-color);
    margin-bottom: 20px;
  }
  
  .thank-you-message {
    font-size: 1.25rem;
    color: #555;
    margin-bottom: 30px;
  }
  

  