/* Footer.css */

.footer {
    background-color: #ffffff !important;
    padding: 40px 0;
    color: #fff;
  }
  
  .footer .footer-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .footer .footer-logo {
    height: 40px;
  }
  
  .footer .footer-description {
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .footer .contact-info li {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  
  .footer .contact-info li .fa-icon {
    margin-right: 0.5rem;
  }
  
  .footer .list-unstyled a {
    color: #333;
    text-decoration: none;
  }
  
  .footer .list-unstyled a:hover {
    text-decoration: underline;
  }
  
  .footer .subscribe-form .input-group {
    max-width: 400px;
  }
  
  .footer .subscribe-form .form-control {
    border-radius: 25px 0 0 25px;
  }
  
  .footer .subscribe-form .btn {
    border-radius: 0 25px 25px 0;
  }
  
  .footer .app-icon {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .footer .social-links .list-inline-item {
    margin-right: 0.5rem;
    color: #fff !important;
    font-size: 1.5rem;
  }
  
  .footer .social-links .fa-icon {
    color: #626262 !important;
    font-size: 1.5rem;
  }
  
  .footer .social-links .fa-icon:hover {
    color: #007bff;
  }
  a{
    color: #aeb5bd !important;
  }
  .footer .text-center {
    font-size: 0.875rem;
    color: #666;
  }
  .btn-subscribe {
    border-bottom-right-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background-color: var(--primary-color);
    color: white;
  }

.footer-link {
  margin-bottom: 6px;
}