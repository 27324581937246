.store-location-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .store-location-title,
  .store-location-subtitle {
    text-align: center;
  }
  
  .store-location-form .form-group {
    margin-bottom: 15px;
  }
  
  .store-location-input {
    height: 45px;
  }
  
  .store-location-button {
    width: 100%;
    padding: 10px;
  }
  
  .store-location-map {
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .pac-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .pac-input {
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loader-content {
    text-align: center;
    font-size: 20px;
    color: #000;
  }
  
  .btn-secondary-store {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    background-color: var(--primary-color);
    color: white;
  }
  .btn-secondary-store::hov {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    background-color: var(--primary-color) !important;
    color: rgb(0, 0, 0);
  }

  gmp-placeautocomplete {
    width: 100%;
    height: 38px; /* or match your input height */
    display: block;
    margin-bottom: 1rem;
}