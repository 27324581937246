/* CategoryListFilter.css */
.category-list-filter {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}


.category-list-filter h6 {
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #eee;
  font-weight: 600;
  text-align: left;
}

.list-group-category-filter {
  padding: 10px 0;
  text-align: left;
}

.category-filter-list {
  padding: 0 !important;
  border: none !important;
  transition: all 0.2s ease;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.category-filter-list a {
  text-decoration: none;
  color: #555 !important;
  font-weight: 500;
  flex: 1;
}

/* Style active category with primary color */
.category-filter-list.active > .category-header {
  background-color: var(--primary-color);
}

.category-filter-list.active > .category-header > a {
  color: #ffffff !important;
  font-weight: 600;
}

.category-filter-list.active > .category-header > .expand-button {
  color: #ffffff;
}

.subcategory-list {
  border-radius: 0px;
  padding-left: 20px !important; /* Indent subcategories */
}

.sub-subcategory-list {
  padding: 8px 15px 8px 25px !important;
  border: none !important;
  background-color: transparent !important;
}

.sub-subcategory-list a {
  color: #777 !important;
  font-size: 0.95em;
  text-decoration: none;
}

/* Only color the active subcategory */
.sub-subcategory-list.active > a {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.sub-subcategory-list:hover {
  background-color: #f8f9fa !important;
}

.expand-button {
  background: none;
  border: none;
  color: #777;
  font-size: 18px;
  padding: 0 8px;
  cursor: pointer;
  line-height: 1;
}

.expand-button:hover {
  color: var(--primary-color);
}