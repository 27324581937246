.policy-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .policy-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .policy-container p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  