/* src/components/CartIcon.css */

.cart-icon-container {
    position: relative;
  }
  
  .cart-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 50%;
    padding: 3px 7px !important;
    font-size: 16px !important;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  