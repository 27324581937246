/* Cart.css */

.cart-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.cart-empty {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
}

.cart-list {
  list-style-type: none;
  padding: 0;
}

.cart-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-name {
  font-size: 20px;
  margin: 0;
  color: #33506a;
}

.cart-item-quantity {
  font-size: 0.9rem;
  color: #777;
  text-align: center;
}

.cart-item-price {
  font-size: 1.1rem;
  margin-right: 20px;
}

.cart-item-remove {
  background-color: transparent;
  border: none;
  color: rgb(28, 28, 28);
  cursor: pointer;
}

.cart-total {
  font-size: 1.3rem;
  margin-top: 20px;
  text-align: right;
}

.cart-checkout {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
  margin-top: 20px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.cart-checkout:hover {
  background-color: #452b00;
}

.cart-icon-container {
  position: relative;
}

.cart-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  padding: 2px 5px !important;
  font-size: 12px !important;
  font-weight: bold;
  display: flex;
  align-items: center;
}
