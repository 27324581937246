.similar-products .card {
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.similar-products .card-img-top {
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.similar-products .card-body {
  text-align: center;
}

.similar-products .badge-discount {
  background-color: #28a745;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.material-icons {
  margin-right: 8px; /* Adjust spacing between icon and text */
}

.wishlist-container {
  margin-top: 20px;
}

.wishlist-container h3 {
  margin-bottom: 20px;
}

.wishlist-product-name {
  width: fit-content;
}