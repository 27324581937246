.text-center {
    text-align: center;
  }
  
  .btn-secondary {
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 0px;
    margin-bottom: 20px;
  }
  