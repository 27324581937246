.breadcrumb-path-product-detail {
  margin: 30px 0;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.price-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.product-price {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  margin-right: 15px;
}
.descriptionText {
  margin: 40px;
}
.product-original-price {
  text-decoration: line-through;
  color: #6c757d;
  margin: 0;
  font-size: 20px;
}

.product-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product-condition-product-detail {
  border-radius: 25px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: var(--accent-color) !important;
  margin: 0;
}

.btn-wishlist {
  background: transparent !important;
  color: #333 !important;
  border: 2px solid #eee !important;
  padding: 8px 15px;
  border-radius: 25px !important;
  transition: all 0.3s ease;
}

.btn-wishlist:hover {
  background: #f8f9fa !important;
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.quantity-control {
  background: #f8f9fa;
  padding: 8px;
  border-radius: 8px;
  margin-right: 15px;
}

.quantity-control button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.quantity-control input {
  border: none;
  background: transparent;
  font-weight: 600;
}

.in-stock-number {
  background: #e8f5e9;
  padding: 8px 15px;
  border-radius: 25px;
  color: #2e7d32;
  font-size: 14px;
  margin: 0;
}

.button-group {
  display: flex;
  width: 100%;
  gap: 10px;
  margin: 25px 0;
}

.btn-add-to-cart,
.btn-order-whatsapp,
.btn-remove-from-cart {
  flex: 1;
  height: 50px;
  border-radius: 8px !important;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.2s ease;
}
.btn-order-whatsapp {
  background: white;
  border: white;
  color: #2e7d32;
}
.btn-add-to-cart:hover,
.btn-order-whatsapp:hover,
.btn-remove-from-cart:hover {
  transform: translateY(-2px);
}

.carousel {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-bottom: 30px;
}

.carousel-item img {
  height: 400px;
  object-fit: cover;
  background: #f8f9fa;
}

.product-features {
  margin: 20px 0;
  padding: 0;
}

.product-features li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-features li:before {
  content: "•";
  color: var(--primary-color);
  font-size: 20px;
}

.nav-tabs {
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  border: none;
  color: #666;
  font-weight: 500;
  padding: 15px 25px;
  position: relative;
}

.nav-tabs .nav-link.active {
  color: var(--primary-color);
  background: transparent;
  border: none;
}

.nav-tabs .nav-link.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
}

@media (max-width: 768px) {
  .button-group {
    flex-direction: column;
  }
  
  .carousel-item img {
    height: 300px;
  }
  
  .product-price {
    font-size: 24px;
  }
  
  .product-original-price {
    font-size: 18px;
  }
}
/* Features Tab */
.product-features {
  margin: 40px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.product-features li {
  background: #f8f9fa;
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #eee;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: transform 0.2s ease;
}

.product-features li:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-features li:before {
  content: "✓";
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
}

/* Colors Tab */
.colors-list {
  margin: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.color-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eee;
}

.color-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.color-name {
  font-size: 15px;
  color: #333;
}

/* Tab Navigation */
.nav-tabs {
  border-bottom: 2px solid #eee;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.nav-tabs .nav-link {
  border: none;
  color: #666;
  font-weight: 500;
  padding: 15px 30px;
  position: relative;
  transition: all 0.3s ease;
}

.nav-tabs .nav-link:hover {
  color: var(--primary-color);
}

.nav-tabs .nav-link.active {
  color: var(--primary-color);
  background: transparent;
  font-weight: 600;
}

.nav-tabs .nav-link.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
}

/* Quantity Controls */
.quantity-control {
  background: #f8f9fa;
  padding: 8px;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;  /* Changed */
  align-items: center; /* Changed */
  height: 50px; /* Added fixed height */
}

.quantity-control button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
}

/* Updated input styles */
.quantity-control input {
  border: none;
  background: transparent;
  font-weight: 600;
  width: 60px !important;
  height: 35px !important; /* Match button height */
  text-align: center;
  margin: 0 8px !important;
  padding: 0 !important;
}

/* Remove the up/down arrows from number input */
.quantity-control input::-webkit-outer-spin-button,
.quantity-control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-control input[type=number] {
  -moz-appearance: textfield;
}