/* CategoryView.css */

/* General Styles */
.container {
  margin-top: 20px;

}

.header {
  margin-bottom: 10px;
}

/* Filter Sidebar */
.filter-sidebar {
  background-color: #f1f1f1;
  padding: 0px;
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px !important;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;

}

.filter-sidebar h5 {
  margin-bottom: 15px;
}

.filter-sidebar .filter-group {
  margin-bottom: 20px;
}

.filter-sidebar .filter-group label {
  display: block;
  margin-bottom: 5px;
}

.filter-sidebar .filter-group input[type="range"] {
  width: 100%;
}

.filter-sidebar .filter-group .price-range {
  display: flex;
  justify-content: space-between;
}

/* Product List */
.product-list {
  margin-top: 20px;
}

.product-list .sort-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product-list .sort-options select {
  max-width: 200px;
}

.product-list .product-item {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
}

.product-list .product-item img {
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product-list .product-item h5 {
  margin-bottom: 10px;
}

.product-list .product-item .price {
  color: #007bff;
  font-size: 18px;
}

.product-list .product-item .original-price {
  text-decoration: line-through;
  color: #6c757d;
  margin-left: 10px;
}

.product-list .product-item .discount-badge {
  background-color: #28a745;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
}

.category-product-pricing-area {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination .page-item {
  margin: 0 5px;
}

.category-old-price {
  margin-left: 0px !important;
  font-size: 12px !important;
}

.category-price {
  margin-left: 0px !important;
  font-size: 14px !important;
}

.pagination .page-item a {
  color: #007bff;
  text-decoration: none;
}

.pagination .page-item a:hover {
  text-decoration: underline;
}

/* Active link styling */
.active-link {
  color: red !important;
}

/* Responsive Styles */
@media (max-width: 767px) {

  .product-list .product-item {
    padding: 10px;
  }

  .product-list .product-item img {
    max-height: 100px;
  }

  .product-list .sort-options {
    flex-direction: column;
  }

  .product-list .sort-options select {
    margin-top: 10px;
  }

  .pagination {
    flex-direction: row;
    align-items: center;
  }

  .pagination .page-item {
    margin-bottom: 5px;
  }
}

.breadcrumb-path a {
  color: var(--secondary-color) !important;
  font-size: 15px !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important; /* Vertically center */
  justify-content: center !important; /* Horizontally center */
  height: 100% !important; /* Full height of the container */
  text-align: center !important; /* Center text inside the div */
}

.breadcrumb-item.active {
  color: #303030 !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important; /* Vertically center */
  justify-content: center !important; /* Horizontally center */
  height: 100% !important; /* Full height of the container */
  text-align: center !important; /* Center text inside the div */
}

.breadcrumb {
  margin-bottom: 0px !important;
}

.sort-by {
  outline-color: white;
  border: 1px solid #33506a;
}

.btn-toogle-filters {
  background: none !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  align-content: baseline;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
