/* Global styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f8f8f8;
}

/* App.css */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Category List Styling */
.category-list {
    padding: 0px !important;
    border-radius: 5px;
    margin-top: 25px;
}

.category-list ul {
  list-style-type: none;
  padding-left: 0;
}

.category-list .list-group-item {
  background-color: #fff;
  border: 1px solid #ffffff;

}

.category-list .list-group-item ul {
  margin-top: 10px;
}

.category-list .list-group-item ul .list-group-item {
  margin-bottom: 0px;
}

/* Navbar Styling */
.logo {
  height: 30px; /* Adjust the height of the logo as needed */
}

.navbar, .navbar-support-line {
  background-color: var(--primary-color) !important;
}

.navbar-nav .nav-link {
  margin-right: 15px;
}

.navbar-text {
  font-size: 14px;
  font-weight: bold;
  color: white !important;
}

.dropdown-menu {
  min-width: 120px; /* Adjust the width as needed */
}

.navbar .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.navbar .d-flex {
  display: flex;
  align-items: center;
}

.navbar .dropdown-toggle {
  margin-right: 10px;
}

.navbar form {
  max-width: 600px; /* Adjust as needed */
}

.navbar form .form-control {
  width: calc(100% - 40px); /* Adjust based on button width */
}

.navbar form .btn {
  margin-left: -40px; /* Adjust based on button width */
}

.nav-link.d-flex.align-items-center {
  display: flex;
  align-items: center;
  color: #000 !important;
  font-weight: normal;
  font-size: 12px;
}
.item-mobile {
  color: white !important;
  font-weight: 600;
  font-size: 23px;
}

.nav-link.d-flex.align-items-right {
  display: flex;
  align-items: end;
  color: var(--primary-color) !important;
  font-weight: normal;
  font-size: 18px;
}

.nav-link.d-flex.align-items-center i {
  margin-right: 5px;
}

.nav-link.d-flex.align-items-left {
  color: #000 !important;
  font-weight: normal;
  align-items: start;
  font-size: 12px;
}

.nav-link.d-flex.align-items-left i {
  margin-right: 5px;
}

.navbar .container-fluid .d-flex .nav-link {
  margin-left: 20px;
}

.navbar .navbar-text {
  margin-left: auto;
}

.navbar .dropdown {
  margin-left: 10px;
}

/* Removed flex-grow from .ms-auto to allow proper alignment */
.navbar .d-flex .ms-auto {
  display: flex;
  align-items: center;
}

.navbar-support-line {
  background-color: #f8f9fa;
  padding: 10px 0;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.main-nav {
  width: 100%;
  top: 0px; /* Adjust based on the height of the support line */
  z-index: 999;
}

.divider {
  height: 0.5px;
  background-color: #000000;
}

.divider1 {
  margin-top: 5px;
  height: 0.5px;
}

.container {
  margin-top: 0px; /* Adjust based on the height of both fixed navbars */
}

.bg-light {
  background-color: #202020 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.support-links .nav-link {
  margin-right: 20px; /* Add space between support links */
  color: #adb5bd !important;
}

.nav-button {
  --bs-btn-color: #ffffff !important;
}

/* Search bar styling */
.search-bar {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
}

.search-bar .input-group {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  background-attachment: #dadada;
  font-size: 20px;
}

.search-bar .input-group-text {
  background-color: #dadada;
  border: none;
  padding-left: 20px;
}

.search-bar .form-control {
  border: none;
  box-shadow: none;
  height: 45px;
  background-color: #ffffff;
}

/* Wishlist, Compare List, and Cart Icons Styling */
.wishlist-compare-cart {
  display: flex;
  align-items: center;
}

.wishlist-compare-cart .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
}

.wishlist-compare-cart .nav-link i {
  font-size: 24px; /* Increase the size of the icons */
}

.wishlist-compare-cart .nav-link span {
  margin-top: 6px;
  font-size: 12px; /* Adjust the font size of the labels */
}

.navbar-support-line .container-fluid {
  display: flex;
  justify-content: space-between;
}

.navbar-support-line .container-fluid .d-flex {
  gap: 10px;
}

.navbar-support-line .container-fluid .support-links {
  display: flex;
  gap: 10px;
}

.custom-icon {
  margin-bottom: 5px;
  color: white;
  font-size: 1.5em !important; /* 1.5 times the base font size */
}
.custom-icon-cart {
  margin-bottom: 5px;
  color: white;
  font-size: 1.5em; /* 1.5 times the base font size */
}
.wishlistText {
  color: white;
}
.search-bar .input-group {
  width: 100%;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin-left: 15px;
  color: #6c757d !important;
}
.search-input {
  border-left: none;
  flex: 1;
}

/* Change placeholder color to orange */
.search-input::placeholder {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: normal;
}


/* Ensure the navbar elements are aligned properly */
.navbar-nav .nav-link {
  margin-left: 15px;
}

.navbar-toggler {
  border: none;
  outline: none;
  font-size: 1.8rem;
  padding: 0;
  margin-right: 10px;
  color: #282c34;
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: auto; /* Ensure logo stays on the left */
}

/* Ensure the search bar and icons are centered and aligned properly */
@media (max-width: 767px) {
  .mobo {
    width: inherit;
    font-size: 1.4em;
  }

  .navbar-nav {
    text-align: left;
  }

  .search-bar {
    margin-top: 20px;
  }

  .nav-item {
    width: 100%;
  }

  .nav-item .nav-link {
    width: 100%;
    display: block;
  }

  .navbar .container-fluid {
    justify-content: center;
  }

  .navbar .container-fluid .ms-auto {
    justify-content: flex-end;
  }

  .navbar .container-fluid .navbar-brand {
    margin-left: 10px;
  }
}

.home-section {
  background: white;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.card {
  border-color: #fff !important;
}

.nav-fixed {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.home-section-2 {
  padding-top: 120px;
}
.card-text {
  margin-bottom: 5px !important;
}

.btn-danger{
  background-color: #000000;
  outline: none;
  border: none;
  height: 48px;
  border-radius: 4px !important;
  color: #fff !important;
}
.card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler {
  border: none; /* remove default border */
  padding: 0.25rem 0.75rem; /* adjust padding if needed */
}

.navbar-toggler:focus {
  outline: none; /* remove default focus outline */
  box-shadow: none; /* remove any box shadow */
}

.navbar-toggler .material-icons {
  font-size: 1.5rem; /* adjust the size of the icon */
}

.profile-link-phone {
  background: black !important;
    width: fit-content !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    color: white !important;
    border-radius: 20px !important;
    font-size: 14px !important;
}

.btn-logout {
  width: fit-content !important;
}

.top-selling-container h5 {
  text-align: left;
}
.banner-img {
  height: 0px;
  object-fit: fill !important;
  width: 100%;
}
.item-admin {
  background-color: var(--primary-color) !important;
}