/* Main Container */
.checkout-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background: white;
  border-radius: 20px;
}

/* Title */
.checkout-title {
  font-size: 28px;
  font-weight: 700;
  color: #1a1f36;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 15px;
}

.checkout-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--primary-color);
  border-radius: 2px;
}

/* Form Elements */
.form-group {
  margin-bottom: 25px;
}

.checkout-input-label {
  display: block;
  font-size: 0.95rem;
  font-weight: 600;
  color: #1a1f36;
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  padding: 12px 16px;
  font-size: 1rem;
  border: 2px solid #e5e9f2;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
  outline: none;
}

/* Pickup Stations Section */
.pickup-stations {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 20px;
  margin-top: 15px;
}

.country-group {
  margin-bottom: 20px;
}

.country-title {
  font-size: 1.1rem;
  color: #1a1f36;
  padding: 12px 20px;
  background: white;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.country-title:hover {
  background: #f0f2f5;
}

.locations {
  padding: 10px 0;
}

.city-group {
  margin: 10px 0;
}

.city-title {
  padding: 10px 20px;
  color: #4a5568;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.city-title:hover {
  background: #edf2f7;
}

.specific-locations {
  padding: 10px 0;
}

.specific-location {
  padding: 8px 40px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.specific-location:hover {
  background: #f8fafc;
}

/* Home Delivery Section */
.home-delivery-section {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 16px;
  margin-top: 20px;
}

.btn-secondary-store {
  width: 100%;
  padding: 14px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin: 15px 0;
}

.btn-secondary-store:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.store-location-map {
  height: 400px;
  border-radius: 16px;
  margin: 20px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Checkout Summary */
.checkout-summary {
  margin-top: 30px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 16px;
}

.cost-summary {
  font-size: 1.1rem;
  color: #4a5568;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cost-summary:last-child {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #e2e8f0;
  font-size: 1.25rem;
  color: #1a1f36;
}

/* Payment Button */
.checkout-payment {
  width: 100%;
  padding: 16px;
  margin-top: 30px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.checkout-payment:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.checkout-payment:disabled {
  background: #cbd5e0;
  cursor: not-allowed;
}

/* Search Input */
.pac-input {
  width: 100%;
  padding: 14px 16px;
  border: 2px solid #e5e9f2;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pac-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
}
/* Add this to your Checkout.css */
.pac-container {
  z-index: 9999;
  border-radius: 12px;
  border: 1px solid #e5e9f2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  margin-top: 5px;
}

.pac-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.pac-item:hover {
  background-color: #f8f9fa;
}

.pac-item-selected {
  background-color: #f0f2f5;
}

/* Hide the Google Powered logo */
.pac-logo:after {
  display: none;
}

/* Loading State */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-content {
  text-align: center;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Custom Radio Buttons */
.pickup-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #e2e8f0;
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pickup-radio:checked {
  border-color: var(--primary-color);
}

.pickup-radio:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: var(--primary-color);
  border-radius: 50%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .checkout-container {
    padding: 20px;
    margin: 20px;
  }

  .checkout-title {
    font-size: 24px;
  }

  .store-location-map {
    height: 300px;
  }

  .country-title,
  .city-title {
    padding: 10px 15px;
  }

  .specific-location {
    padding: 8px 20px;
  }
}
/* Styling for the place autocomplete element */
gmp-placeautocomplete {
  width: 100%;
  margin-bottom: 1rem;
}

/* Style the input inside the place autocomplete */
gmp-placeautocomplete input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Hover state */
gmp-placeautocomplete input:hover {
  border-color: #a0a8b1;
}

/* Focus state */
gmp-placeautocomplete input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Style the dropdown container */
.pac-container {
  border-radius: 4px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  font-family: inherit;
}

/* Style individual dropdown items */
.pac-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pac-item:hover {
  background-color: #f8f9fa;
}

/* Style the matched text in dropdown */
.pac-item-query {
  font-size: 15px;
  color: #212529;
}

/* Add some spacing around the search box container */
.form-group {
  margin-bottom: 1.5rem;
}

/* Make sure the input container has proper spacing */
.controls-container {
  margin-bottom: 1rem;
  position: relative;
}

.text-muted {
  margin-top: 10px;
  margin-bottom: 10px;
}