.age-filter h6 {
    margin-top: 20px;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
  }
  
  .btn-filter {
    border: 1px solid var(--accent-color) !important;
    border-radius: 25px !important;
    background-color: white;
    color: var(--accent-color);
    font-size: 15px;
    padding: 5px 20px !important;
    width: fit-content !important;
    margin-bottom: 0px !important;
    cursor: pointer !important;
    transition: all 0.3s ease;
  }
  
  .btn-filter.selected {
    background-color: var(--accent-color);
    color: white;
  }
  
  .btn-filter:hover {
    background-color: var(--accent-color);
    color: white;
  }
  