.product-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-form .form-label {
    font-weight: bold;
    color: #343a40;
  }
  
  .product-form .form-control {
    margin-bottom: 15px;
    border-radius: 4px;
  }
  
  .product-form .image-previews {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .product-form .preview-image {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    object-fit: cover;
  }
  
  .product-form .product-form-submit {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #007bff;
    border: none;
    color: white;
    transition: background-color 0.3s;
  }
  
  .product-form .product-form-submit:hover {
    background-color: #0056b3;
  }
  
  .product-form .alert {
    margin-top: 20px;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .product-form {
      padding: 10px;
    }
  
    .product-form .form-control {
      margin-bottom: 10px;
    }
  
    .product-form .preview-image {
      width: 50px;
      height: 50px;
    }
  }
  