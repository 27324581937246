.large-top-banner {
    background-color: white;
    padding: 5px;
    margin-top: 20px;
    width: 100%;
  }
  
  .banner-image {
    width: 100%;
    max-height: 45vh;
    border-radius: 0px; /* Optional: rounded corners */
  }
  