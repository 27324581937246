.price-range-filter {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0px;
  margin-top: 20px;
}

.price-range-filter h2 {
  margin-bottom: 10px;
}

.horizontal-slider {
  width: 100%;
  height: 8px;
  margin: 10px 0;
  background: #6f6f6f;
  border-radius: 5px;
  position: relative;
}
.track:first-of-type {
  background-color: #ccc !important;
}
.track {
  height: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--accent-color);
  
}
.track:first-of-type {
  background-color: #ccc;
}

.track:nth-of-type(3) {
  background-color: #ccc;
}

.thumb {
  height: 20px;
  width: 20px;
  background-color: var(--accent-color);
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.price-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  width: 100%;
}

.price-inputs input[type='number'] {
  width: 60px;
  height: 30px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0px;
}

.price-inputs span {
  margin: 0 5px;
  font-size: 18px;
}

.filter-button {
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  color: white;
}

.filter-button:hover {
  background-color: #ffffff;
}
.price-range-input {
  width: 100% !important;
  border: 1px solid #32506b !important;
}