.category-list {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 0px;
    margin-top: 20px;
    position: relative;
  }
  
  .category-list ul {
    list-style-type: none;
    padding-left: 0;
    
  }
  .list-group {
    background: white;
  }
  
  .category-list .list-group-item {
    background-color: #fff;
    margin-bottom: 5px;
    position: relative;
  }
  
  .category-list .list-group-item .subcategory-list {
    display: none;
  }
  
  .category-list .list-group-item .subcategory-list.expanded {
    display: block;
  }
  
  .category-list .list-group-item .subcategory-item {
    padding-bottom: 10px;
    cursor: pointer;
  }
  
  .category-list .list-group-item .subcategory-item:hover {
    background-color: #f0f0f0;
  }
  
  .header {
    margin-bottom: 10px;
    margin-left: 5px;
  }
  
  @media (min-width: 768px) {
    .category-list .list-group-item .subcategory-list.expanded {
      position: absolute;
      top: 0;
      left: 100%;
      width: 200px; /* Adjust width as needed */
      z-index: 10;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 0px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-top: 0px !important;
    }
  }
  
  .category-list img {
    display: inline-block;
    vertical-align: middle;
  }
  
  .category-list .list-group-item {
    border-radius: 0;
  }
  
  .category-list .list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .category-list .list-group-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }