.feature-section {
    margin: 20px 0; /* Adjust margin as needed */
    background-color: var(--primary-color); /* Adjust background color as needed */
    border-radius:0px; /* Optional: rounded corners */
  }
  
  .feature {
    text-align: center;
    padding-left: 20px; /* Adjust padding as needed */
    padding-right: 20px;
    margin-top: 30px !important;
    padding-bottom: 10px;
  }
  
  .feature-icon {
    color: #f9f9f9a3; /* Adjust icon color as needed */
    font-size: 40px; /* Adjust icon size as needed */
    margin-bottom: 10px; /* Adjust margin as needed */
  }
  
  .feature h4 {
    font-size: 18px; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 10px; /* Adjust margin as needed */
    color: #fff;
  }
  
  .feature p {
    font-size: 14px; /* Adjust font size as needed */
    color: #fff; /* Adjust text color as needed */
  }
  .features {
    margin: 0 !important;
    padding: 0 !important;
  }
 
  