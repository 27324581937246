.faq-list {
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-list h2 {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
  }
  
  .faq-question {
    background-color: #ffff;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .faq-answer {
    background-color: #e9ecef;
    color: #333;
  }
  