.card-img-top {
  height: 200px; /* Set a fixed height for the images */
  object-fit: cover; /* Maintain aspect ratio and crop if necessary */
  width: 100%; /* Ensure the image takes the full width of the card */
}

.card- {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.margin-class {
  margin-bottom: 15px;
}
.no-padding {
  padding: 0px !important;
}
.no-margin {
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;

}

.my-4 {
  color: #333;
}
.top-selling-title {
  margin-bottom: 10px;
}
.product-name-top-selling {
  margin-top: 10px;
  margin-bottom: 5px !important;
  font-size: 14px !important;
  
}
.product-new-price-top-selling {
  font-size: 12px !important;
}
.product-old-price-top-selling {
  font-size: 12px !important;
}
.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.badge-discount {
  background-color: var(--secondary-color);
  color: white;
  padding: 5px 10px;
  font-size: 0.9em;
  border-radius: 5px;
}

.price-new {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}

.price-old {
  font-size: 1em;
  text-decoration: line-through;
  color: #999;
  margin-left: 10px;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.btn-primary {
  background-color: var(--primary-color);
  outline: none;
  height: 48px;
  border: none;
  border-radius: 4px !important;
  color: #fff !important;
}

.btn-primary:hover {
  color: var(--bs-btn-hover-color);
  background-color: #32506b;
  border-color: #32506b;
}

.zoom-image {
  transition: transform 0.5s ease;
}

.zoom-image:hover {
  transform: scale(1.2);
}
.product-condition {
  border-radius: 20px;
  padding-left: 10px;
  padding-top: 1px;
  padding-right: 10px;
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 5px !important;
  padding-bottom: 1px;
  font-weight: bold;
  color: white;
  background-color: var(--accent-color) !important;
}

.view-more-button {
  background: none !important;
  background-color: white !important;
  color: black !important;
  font-size: 18px !important;
  outline: none !important;
  border: none !important;
  border-color: white !important;
  width: 100%;
}
