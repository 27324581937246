.modal-title {
    font-size: 26px;
    color: #000000;
}
.modal-body {
    padding: 30px !important;
}
.modal-content-body {
    padding: 15px;
    
}
.modal-content{
    border-radius: 0 !important;
}
.modal-product-name {
    font-size: 24px;
}
.btn-modal-close {
    border: 0px !important;
    margin: 0 !important;
    border-radius: 2px !important;
    margin-right: 10px !important;
    background-color: #6b6b6b;
}

.btn-modal-add-to-cart {
    border: 0px !important;
    margin: 0 !important;
    border-radius: 2px !important;
}

.center-txt {
    text-align: center;
}