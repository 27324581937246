.manage-products-container {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-section {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.manage-products-title {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.controls-section {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.product-table {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.product-table thead {
  background-color: #2c3e50;
  color: white;
}

.product-table th {
  padding: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.product-table td {
  padding: 1rem;
  vertical-align: middle;
}

.product-images {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  min-width: 120px;
}

.manage-products-container {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-section {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.manage-products-title {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.controls-section {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.product-table {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.product-table thead {
  background-color: #2c3e50;
  color: white;
}

.product-table th {
  padding: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.product-table td {
  text-align: left;
  padding: 1rem;
  vertical-align: middle;
}

.product-images {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  min-width: 120px;
}

.product-image-container {
  position: relative;
  cursor: pointer;
}

.product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  transition: transform 0.3s ease;
}

.product-image-container:hover .product-image {
  transform: scale(2);
  position: relative;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.stock-badge {
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 20px;
  white-space: nowrap;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.action-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 4px;
}

.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.image-preview-container {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
}

.image-preview {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.pagination {
  margin: 2rem 0;
}

.pagination .page-link {
  color: #2c3e50;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.pagination .page-item.active .page-link {
  color: white;
  background-color: #2c3e50;
  border-color: #2c3e50;
}

.pagination .page-link:hover:not(.active) {
  background-color: #e9ecef;
}

.alert {
  margin-bottom: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-control:focus,
.form-select:focus {
  border-color: #2c3e50;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

@media (max-width: 768px) {
  .manage-products-container {
    padding: 1rem;
  }

  .controls-section .row {
    row-gap: 1rem;
  }

  .table-responsive {
    margin-top: 1rem;
    font-size: 0.9rem;
  }

  .product-image {
    width: 40px;
    height: 40px;
  }

  .action-buttons {
    flex-direction: column;
  }

  .stock-badge {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }

  .header-section,
  .controls-section {
    padding: 1rem;
  }

  .manage-products-title {
    font-size: 1.5rem;
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Custom scrollbar */
.table-responsive::-webkit-scrollbar {
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #2c3e50;
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #34495e;
}

/* Loading spinner customization */
.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.25rem;
}

/* Hover effect for table rows */
.product-table tbody tr:hover {
  background-color: rgba(44, 62, 80, 0.05);
}

/* Button hover effects */
.btn-outline-primary:hover {
  background-color: #2c3e50;
  border-color: #2c3e50;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  border-color: #dc3545;
}